import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MessageContextInput } from '@x/schemas/ai';

export type AiContext = {
  pageCode: string;
  itemId?: number;
  channelCode?: string;
  username?: string;
  additionalInformation?: string;
};

@Injectable({ providedIn: 'root' })
export class AiContextService {
  AI_CONTEXT: AiContext | null = {
    pageCode: '',
    itemId: undefined,
    channelCode: '',
    username: '',
    additionalInformation: '',
  };

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Clear the context on navigation start
        this.clearContext();
      }
    });
  }

  getCurrentContext(): MessageContextInput {
    return this.AI_CONTEXT as MessageContextInput;
  }

  setContext(context: AiContext) {
    this.AI_CONTEXT = context;
  }

  clearContext() {
    this.AI_CONTEXT = null;
  }
}
